import ApiUrl from "../../../config/config";
import React, { useState, useEffect } from "react";
import "./Admin-login.css";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { Routes_Main } from "../../../MainRoutes";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import PulseLoader from "react-spinners/PulseLoader";


const AdminLogin = (props) => {
  useEffect(() => {
    document.title = "OTC Markets | Admin Login";
  }, []);
  let navigate = useNavigate();
  const [showHide, setShowHide] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const UserLogin = () => {
    setLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      username: username,
      password: password,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(ApiUrl + "/UserLogin", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        setLoader(false);
        if (result.status === 200) {
          let items = {
            access_token: result.access_token,
            refresh_token: result.refresh_token,
          };
          console.log("I am Here!!!");
          localStorage.setItem("AdminAuth", JSON.stringify(items));
          localStorage.setItem("AdminIsLogin", "true");
          toast.success("Logged in Successfully");
          navigate("/manage-companies")

          props.setRefreshAdminLogin(
            props.RefreshAdminLogin === true ? false : true
          );
         
        } else {
          setLoader(false);
          toast.error(result.message);
          //     if (result.status === 405) {
          //       setVerificationLink(result.link);
          //     }
        }
      })
      .catch((error) => 
      {
        console.log("error", error);
        toast.error("There is error please try again");
        setLoader(false);
    }
      );
  };
  return (
    <div className="login-container">
      <div className="card">
        <form>
          <div className="text-left">
            {/* <div className="flexflex mb-2">
              <img src={MainLogo} width="250px" />
            </div> */}
            <p className="below-text text-center mb-0">
              <b>Admin Login</b>
            </p>
            <p className="text-center text-secondary mb-0">
              Use your credentials to access your account.
            </p>
          </div>
          <div>
            <div className="inputbox">
              <span>
                Email<span className="text-danger">*</span>
              </span>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="form-control"
                required="required"
                placeholder="Enter your email"
              />
            </div>
            <div className="inputbox mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <span>
                  Password <span className="text-danger">*</span>
                </span>
                
              </div>
              <div>
                <input
                  type={showHide ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  required="required"
                  placeholder="Enter your password"
                />
                <div
                  className="flexflex"
                  style={{
                    float: "right",
                    marginTop: "-32px",
                    marginRight: "12px",
                    fontSize: "18px",
                    width: "25px",
                    cursor: "pointer",
                  }}
                >
                  {showHide ? (
                    <AiOutlineEyeInvisible
                      onClick={() => setShowHide(!showHide)}
                    />
                  ) : (
                    <AiOutlineEye onClick={() => setShowHide(!showHide)} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 proceed">
            {loader ? (
              <button className="btn-primary btn-full mt-3">
                <PulseLoader size={10} color={"#fff"} />
              </button>
            ) : (
              <button
                className="btn-primary btn-full mt-3"
                type="submit"
                onClick={(e) => (e.preventDefault(), UserLogin())}
              >
                Login
              </button>
            )}
          </div>
          {/* <div className="mt-2">
                <button className="btn-secondary btn-full"><i className="fa fa-google"></i> Register</button>
            </div> */}
        </form>
      </div>
    </div>
  );
};
export default AdminLogin;
