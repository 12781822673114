import { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import ScaleLoader from "react-spinners/ScaleLoader";
import AdminRefreshToken from "../../components/Admin/Admin-Refresh-Token/AdminRefreshToken";
import ApiURL from "../../config/config";
import PaginationButtons from "../../components/Pagination/PaginationButtons";
import { Link } from "react-router-dom";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import { Helmet } from "react-helmet";

function ManageAdminsTest() {
  const [Loading, setLoading] = useState(false);

  const [options, setOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [goto, setGoto] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState([]);
  const [NumberOfRecordsPerPage] = useState(500000);
  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [name, setName] = useState("");
  const [show, setShow] = useState(false);
  const [code, setCode] = useState("");
  const [TokenRefresh, setTokenRefresh] = useState(false);
  const [sharesData, setSharesData] = useState([]);
  const [search, setSearch] = useState("");
  const [clearState, setClearState] = useState(false);
  const handleShowAddModal = () => setShowAddModal(true);
  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };
  const handleShowFilterModal = () => setShowFilterModal(true);
  const handleCloseFilterModal = () => {
    setShowFilterModal(false);
  };

  const handleOnChange = (e) => {
    console.log(e.target.value);
  };

  const handleSaveCompany = () => {
    setLoading(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const AdminAuth = JSON.parse(localStorage.getItem("AdminAuth"));
    myHeaders.append("Authorization", "Bearer " + AdminAuth.access_token);
    let raw = JSON.stringify({
      code: code,
      name: name,
    });
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(ApiURL + "/AddCompany", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.msg === "Token has expired") {
          AdminRefreshToken({ TokenRefresh, setTokenRefresh });
          handleSaveCompany();
        } else if (result.status === 406) {
          toast.error(result.message);
        } else {
          toast.success(result.message);
          setName("");
          setCode("");

          handleCloseAddModal();
          setRefresh(!refresh);
        }
      })
      .catch((e) => {
        console.log("error", e);
        toast.error("An error has occurred");
      });
    setLoading(false);
  };
  const [sortVal, setSortVal] = useState(null);
  let [filteredArray, setFilteredArray] = useState([]);
  const DashboardData = () => {
    setLoading(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const AdminAuth = JSON.parse(localStorage.getItem("AdminAuth"));
    myHeaders.append("Authorization", "Bearer " + AdminAuth.access_token);

    // let filteredArray = []
    if (filterOptions.length > 0) {
      filteredArray = filterOptions.map((val) => val.value);
      console.log(filteredArray);
    }

    // if (e !== undefined) {
    //   sortVal = e.target.value
    // }

    let raw = JSON.stringify({
      query: search,
      // sort: sortVal,
      // filter: filteredArray
    });
    console.log("RAW-----", raw);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,

      redirect: "follow",
    };
    fetch(
      `${ApiURL}/Dashboard/${NumberOfRecordsPerPage}/${
        (currentPage - 1) * NumberOfRecordsPerPage
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.msg === "Token has expired") {
          AdminRefreshToken({ TokenRefresh, setTokenRefresh });
          setRefresh(!refresh);
        } else if (result.status === 404) {
          setSharesData([]);
          toast.error(result.message);
        } else if (result.status === 200) {
          let options = result.data.slice(0, 5).map((val) => {
            return { label: val.name, value: val.company_code };
          });
          setOptions(options);
          setSharesData(result.data);
          setTotalPages(result.pages);
        }
      })
      .catch((e) => {
        console.log("error", e);
        toast.error("An error has occurred");
      });
    setLoading(false);
  };
  useEffect(() => {
    DashboardData();
  }, [refresh, currentPage, clearState, sortVal]);

  if (Loading) {
    return <ScaleLoader />;
  } else {
    return (
      <>
        <Helmet>
          <title>OTC Market | Manage Companies</title>
        </Helmet>
        <div className="container-sm ">
          <div className="flex flex-col-reverse md:flex-row md:justify-between">
            <button
              style={{
                background: "black",
                color: "white",
                borderRadius: "5px",
                padding: "5px 10px",
              }}
              onClick={() => handleShowAddModal()}
            >
              Add Company
            </button>

            <form
              className="form"
              onSubmit={(e) => {
                e.preventDefault();
                currentPage !== 1 ? setCurrentPage(1) : setRefresh(!refresh);
              }}
            >
              <div
                className="position-relative"
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
              >
                <input
                  type="text"
                  className="form-control w-100 px-3 mb-3"
                  placeholder="Search Query"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {show && (
                  <span
                    style={{ right: "0px", top: "0px", cursor: "pointer" }}
                    onClick={() => {
                      setSearch("");
                      setRefresh(!refresh);
                    }}
                    className="position-absolute my-1 mx-2"
                  >
                    {" "}
                    <MdCancel />
                  </span>
                )}
              </div>
            </form>

            {/* ADD Companies MODAL  */}
            <Modal show={showAddModal} onHide={handleCloseAddModal}>
              <Modal.Header closeButton>
                <Modal.Title>Add Company</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="w-100 text-center">
                  <p className="text-danger" id="error"></p>
                </div>
                <div className="p-3 pt-0">
                  <input
                    style={{ border: "1px solid gray", padding: "5px 10px" }}
                    className="w-100 mb-3"
                    type="text"
                    placeholder="Company's Name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    style={{ border: "1px solid gray", padding: "5px 10px" }}
                    className="w-100 mb-3"
                    type="text"
                    placeholder="Company's Ticker"
                    required
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div>
                  <button
                    style={{
                      color: "white",
                      border: "none",
                      backgroundColor: "#f54242",
                      borderRadius: "5px",
                      marginLeft: "8pt",
                      padding: "5px 10px",
                    }}
                    onClick={handleCloseAddModal}
                  >
                    Close
                  </button>
                  <button
                    disabled={Loading}
                    style={{
                      color: "white",
                      border: "none",
                      backgroundColor: "black",
                      borderRadius: "5px",
                      marginLeft: "8pt",
                      padding: "5px 10px",
                    }}
                    onClick={handleSaveCompany}
                  >
                    {Loading === false ? (
                      "Add Company"
                    ) : (
                      <ScaleLoader
                        color={"white"}
                        size={2}
                        style={{ zIndex: "-10", padding: "5px 10px" }}
                      />
                    )}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        {/* TABLE THAT IS DISPLAYING ALL THE DATA  */}
        <div
          className="container table_main mt-4"
          style={{ "overflow-x": "scroll" }}
        >
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Company's Name</th>
                <th scope="col" className="text-center" >Last updated</th>
                <th scope="col" className="text-center" >P1</th>
                <th scope="col" className="text-center" >P2</th>
                <th scope="col" className="text-center">P3</th>
                <th scope="col" className="text-center">P4</th>
                <th scope="col" className="text-center" >P5</th>
                <th scope="col" className="text-center" >P6</th>
                <th scope="col" className="text-center" >Actions</th>
              </tr>
            </thead>
            {Loading === true ? (
              <div className="d-flex position-absolute w-75 justify-content-center">
                <ScaleLoader color={"#25476A"} loading={Loading} size={20} />
              </div>
            ) : (
              <tbody>
                {sharesData.map((value, index) => (
                  <tr>
                    <td className="p-2 border">{value.name}</td>
                    <td className="p-2 border">{value.last_updated}</td>
                    {
                      value.company_data.map((item) => (
                        <td className="p-2 border">
                          <span className="mx-1"> {item.value}</span>
                          <span className={`${item.type === "decrease" ? "text-danger" : item.type === "increase" ? "text-success" : "text-secondary"}`}>{item.type === "decrease" ? <BsArrowDownShort /> : item.type === "increase" ? <BsArrowUpShort /> : null}</span>
                          <span className={`${item.type === "decrease" ? "text-danger" : item.type === "increase" ? "text-success" : "text-secondary"}`} >
                            {item.change}%
                          </span>
                        </td>
                      ))
                    }
                    {
                      [...Array(5 - value.company_data.length + 1)].map((e, i) => (
                        <td className="p-2 border">
                          <span className="mx-1">NULL </span>
                        </td>
                      )
                    )}
                    {value.company_data.length >= 0 && (
                      <td className="border">
                        <button
                          style={{
                            background: "black",
                            color: "white",
                            borderRadius: "5px",
                            padding: "5px 10px",
                          }}
                        >
                          <Link
                            className="text-decoration-none text-reset"
                            to={`/CompanyRecentShares/${value.company_code}`}
                          >
                            View More
                          </Link>
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {/* <PaginationButtons
            totalRecords={totalRecords}
            NumberOfRecordsPerPage={NumberOfRecordsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setGoto={setGoto}
            goto={goto}
            totalPages={totalPages}
          /> */}
        </div>
      </>
    );
  }
}

export default ManageAdminsTest;
