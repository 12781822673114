import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import AdminRefreshToken from "../../components/Admin/Admin-Refresh-Token/AdminRefreshToken";
import ApiURL from "../../config/config";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { Routes_Main } from "../../MainRoutes";
import Papa from "papaparse";

const CompanyRecentShares = () => {
  const [TokenRefresh, setTokenRefresh] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [sharesData, setSharesData] = useState([]);
  const { code } = useParams();

  const RecentSharesData = () => {
    setLoading(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const AdminAuth = JSON.parse(localStorage.getItem("AdminAuth"));
    myHeaders.append("Authorization", "Bearer " + AdminAuth.access_token);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,

      redirect: "follow",
    };
    fetch(`${ApiURL}/CompanyRecentShares/${code}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.msg === "Token has expired") {
          AdminRefreshToken({ TokenRefresh, setTokenRefresh });
          setRefresh(!refresh);
        } else if (result.status === 406) {
          toast.error(result.message);
        } else {
          setSharesData(result.data);
          console.log(result.data);
        }
      })
      .catch((e) => {
        console.log("error", e);
        toast.error("An error has occurred");
      });
    setLoading(false);
  };

  const handleExport = () => {
    var csv = Papa.unparse(sharesData);
    console.log(csv);
    let data = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    let date = new Date();
    var a = document.createElement("a");
    a.href = window.URL.createObjectURL(data);
    a.download = code + " " + date.toLocaleDateString("en-US") + ".csv";
    a.click();
  };
  useEffect(() => {
    RecentSharesData();
  }, []);
  return (
    <div className="main_container">
      <div className="d-flex justify-content-between">
        <button
          style={{
            background: "black",
            color: "white",
            borderRadius: "5px",
            padding: "5px 10px",
          }}
        >
          <Link
            to={`/${Routes_Main.manageAdmins}`}
            className="text-decoration-none text-reset"
          >
            Go back
          </Link>
        </button>
        <button
          style={{
            background: "black",
            color: "white",
            borderRadius: "5px",
            padding: "5px 10px",
          }}
          onClick={handleExport}
        >
          Export
        </button>
      </div>

      <h3 className="text-center text-muted border-bottom mb-3 pb-3">{code}</h3>
      <table className="table">
        <thead>
          <th className="text-center" scope="col">
            Value
          </th>
          <th className="text-center" scope="col">
            Change Value
          </th>
          <th className="text-center" scope="col">
            Date Created
          </th>
          <th className="text-center" scope="col">
            Change
          </th>
        </thead>
        <tbody>
          {sharesData.map((val) => (
            <tr scope="row">
              <td className="text-center">{val.value}</td>
              <td className="text-center">{val.change_value}</td>
              <td className="text-center">{val.date_created}</td>
              <td className="text-center">
                <span
                  className={`${
                    val.type === "decrease"
                      ? "text-danger"
                      : val.type === "increase"
                      ? "text-success"
                      : "text-secondary"
                  }`}
                >
                  {val.type === "decrease" ? (
                    <BsArrowDownShort />
                  ) : val.type === "increase" ? (
                    <BsArrowUpShort />
                  ) : null}
                </span>
                <span
                  className={`${
                    val.type === "decrease"
                      ? "text-danger"
                      : val.type === "increase"
                      ? "text-success"
                      : "text-secondary"
                  }`}
                >
                  {val.change}%
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CompanyRecentShares;
