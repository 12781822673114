import { useEffect, useState } from "react";
import AdminLogin from "../Admin/Admin-Login/AdminLogin";
import Dashboard from "../UserDashboard/Dashboard";

const AdminProtectedRoutes = () => {
  const [AdminIsLogin, setAdminIsLogin] = useState(
    localStorage.getItem("AdminIsLogin")
  );

  const [RefreshAdminLogin, setRefreshAdminLogin] = useState(true);

  useEffect(() => {
    setAdminIsLogin(localStorage.getItem("AdminIsLogin"));
  }, [RefreshAdminLogin]);

  return AdminIsLogin === "true" ? (
    <>
      {console.log("logged in")}
      <Dashboard
        RefreshAdminLogin={RefreshAdminLogin}
        setRefreshAdminLogin={setRefreshAdminLogin}
      />
    </>
  ) : (
    <>
      {" "}
      {console.log("logged out")}
      <AdminLogin
        RefreshAdminLogin={RefreshAdminLogin}
        setRefreshAdminLogin={setRefreshAdminLogin}
      />
    </>
  );
};

export default AdminProtectedRoutes;
