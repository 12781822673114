import { useContext, useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import ViewDetailsModal from "../../components/Modals/ViewDetailsModal";
import { Outlet, useNavigate } from "react-router-dom";
import { ThemeContext } from "../../App";

// icons
import { MdCancel } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";

function LiquidityScanner() {
  const {
    showDetails,
    setShowDetails,
    tableData,
    setTableData,
    Loading,
    setLoading,
  } = useContext(ThemeContext);

  const [show, setShow] = useState(false);

  const [date, setDate] = useState(new Date());
  const [ticker, setTicker] = useState("");
  const [Mtable, setMtable] = useState([]);
  const [tableCount, setTableCount] = useState();
const [sixtyfiveDays,setSixtyFiveDays]=useState()
const [AdjsixtyfiveDays,setAdjSixtyFiveDays]=useState()
  let navigate = useNavigate();

  const GetData = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "Get",
      headers: myHeaders,

      redirect: "follow",
    };
    let startDate = `${date.getFullYear() - 1}-${
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
    }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;
    let endDate = `${date.getFullYear()}-${
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
    }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;
    setLoading(true);
    let api = `https://api.polygon.io/v2/aggs/ticker/${ticker}/range/1/day/${startDate}/${endDate}?adjusted=true&sort=desc&limit=5000&apiKey=tcaxop1WQuVpPiGLC2svmYva3uh7ono8`;
  
    fetch(api)
      // fetch(
      //   `https://api.polygon.io/v2/aggs/ticker/INND/range/1/day/2022-04-27/2023-04-27?adjusted=true&sort=desc&limit=5000&apiKey=tcaxop1WQuVpPiGLC2svmYva3uh7ono8`,

      // )
      .then((response) => response.json())
      .then((result) => {
        let data = [];
        let newDate = new Date();
        // console.log(result);
        if (result.resultsCount > 0) {
          for (let i = 0; i < 12; i++) {
            let start = new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              newDate.getDate() - 1
            );
            let end = new Date(
              newDate.getFullYear(),
              newDate.getMonth() - 1,
              newDate.getDate()
            );
            let newEnd=new Date(end)
          if(newEnd.getDay()===0 ){
            newEnd.setDate(newEnd.getDate()+1)
          }
          else  if(newEnd.getDay()===6){
            newEnd.setDate(newEnd.getDate()+2)
           }
            let volume = 0;
            let dollar = 0;
            result.results?.forEach((val) => {
              let date = new Date(val.t);
              if (date <= start && date >= newEnd) {
                volume = volume + val.v;
                dollar = dollar + val.vw * val.v;
              }
            });
            data.push({
              start: start,
              end: end,
              volume: volume,
              dollar: parseFloat(dollar.toFixed(2)),
            });
            newDate = end;
          }
          let sixfivedayvolumesum=0;
          let sixfivedaydollarsum=0;
for(let i=0;i<65;i++){
  sixfivedayvolumesum=sixfivedayvolumesum+ result.results[i].v
  sixfivedaydollarsum=sixfivedaydollarsum +  result.results[i].vw *  result.results[i].v

}
let volumeAvg=sixfivedayvolumesum/65;
let dollarAvg=sixfivedaydollarsum/65;
let Adjsixfivedayvolumesum=0;
let Adjsixfivedaydollarsum=0;
for(let i=0;i<65;i++){
  if( result.results[i].v/volumeAvg <=1){
    Adjsixfivedayvolumesum=Adjsixfivedayvolumesum+ result.results[i].v
  }
  if( (  result.results[i].vw *  result.results[i].v)/dollarAvg <=1){
  Adjsixfivedaydollarsum=Adjsixfivedaydollarsum +  result.results[i].vw *  result.results[i].v
  }

}
let start = new Date(
  date.getFullYear(),
  date.getMonth(),
  date.getDate() - 1
);
setSixtyFiveDays({
  start: start,
  end: new Date(result.results[64].t),
  volume: sixfivedayvolumesum,
  dollar: parseFloat(sixfivedaydollarsum.toFixed(2)),
})
setAdjSixtyFiveDays({
  start: start,
  end: new Date(result.results[64].t),
  volume: Adjsixfivedayvolumesum,
  dollar: parseFloat(Adjsixfivedaydollarsum.toFixed(2)),
})
          setMtable(data);
          setTableData(
            result.results?.map((val) => ({ ...val, t:new Date(val.t) }))
          );
        
        }
      })
      .catch((e) => {
        console.log("error", e);
        toast.error("An error has occurred");
      });
    setLoading(false);
  };
  // useEffect(() => {
  //   GetData();
  // }, []);
  return (
    <>
      <Helmet>
        <title>OTC Market | Liquidity Scanner</title>
      </Helmet>

      <div className="w-full flex justify-center items-center flex-col-reverse lg:flex-row">
        <form
          className={
            showDetails === true
              ? "form z-40 w-full fixed top-[12.5%]  lg:w-1/3 flex items-center"
              : "form w-full lg:w-1/3 flex items-center"
          }
          onSubmit={(e) => {
            e.preventDefault();
            ticker === "" ? toast.error("Search is Empty") : GetData();
          }}
        >
          <div className="relative w-full flex justify-center self-center">
            <input
              type="search"
              className="w-full border-y border-l border-gray-700 py-2 rounded-md rounded-r-none px-3 "
              onChange={(e) => setTicker(e.target.value.toUpperCase())}
              placeholder="Search Query"
            />
          </div>
          <button className="w-14 h-11 rounded-md rounded-l-none bg-black text-white text-xl flex items-center justify-center">
            <AiOutlineSearch />
          </button>
        </form>
      </div>
      {/* {Loading && <div className="">
  <ScaleLoader  />
</div>} */}
      {/* TABLE THAT IS DISPLAYING ALL THE DATA  */}
      <div className={showDetails === true ? "hidden" : "w-full flex flex-col"}>
        {Mtable?.length >= 1 ? (
          <>
            <div
              className="mt-10 w-full lg:px-10 bg-white rounded-lg shadow-lg"
              style={{ "overflow-x": "scroll" }}
            >
              <table class="table w-full">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col" className="">
                      Start
                    </th>
                    <th scope="col" className="">
                      End
                    </th>
                    <th scope="col" className="">
                      Trading Vol
                    </th>
                    <th scope="col" className="">
                      Dollar Vol
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {Mtable?.map((value, index) => (
                    <tr key={index}>
                      <td className="p-2 border">M{index + 1}</td>
                      <td className="p-2 border">
                        {value.end.getDate() +
                          "-" +
                          (value.end.getMonth() + 1) +
                          "-" +
                          value.end.getFullYear()}
                      </td>
                      <td className="p-2 border">
                        {value.start.getDate() +
                          "-" +
                          (value.start.getMonth() + 1) +
                          "-" +
                          value.start.getFullYear()}
                      </td>

                      <td className="p-2 border">
                        {value.volume.toLocaleString("en-US")}
                      </td>
                      <td className="p-2 border">
                        ${value.dollar.toLocaleString("en-US")}
                      </td>
                    </tr>
                  ))}
                     <tr className="bg-[#dcf4da]">
                      <td className="p-2 border">65d</td>
                      <td className="p-2 border">
                        {sixtyfiveDays?.end.getDate() +
                          "-" +
                          (sixtyfiveDays?.end.getMonth() + 1) +
                          "-" +
                          sixtyfiveDays?.end.getFullYear()}
                      </td>
                      <td className="p-2 border">
                        {sixtyfiveDays?.start.getDate() +
                          "-" +
                          (sixtyfiveDays?.start.getMonth() + 1) +
                          "-" +
                          sixtyfiveDays?.start.getFullYear()}
                      </td>

                      <td className="p-2 border">
                        {sixtyfiveDays?.volume.toLocaleString("en-US")}
                      </td>
                      <td className="p-2 border">
                        ${sixtyfiveDays?.dollar.toLocaleString("en-US")}
                      </td>
                    </tr>
                    <tr className="bg-[#d9ecfc]">
                      <td className="p-2 border">10%</td>
                      <td className="p-2 border">
                       
                      </td>
                      <td className="p-2 border">
                      
                      </td>

                      <td className="p-2 border">
                        {Math.round((sixtyfiveDays?.volume*0.1)).toLocaleString("en-US")}
                      </td>
                      <td className="p-2 border">
                        ${parseFloat((sixtyfiveDays?.dollar*0.1).toFixed(2)).toLocaleString("en-US")}
                      </td>
                    </tr>
                    <tr className="bg-[#dcf4da]">
                      <td className="p-2 border">Adj65d</td>
                      <td className="p-2 border">
                        {AdjsixtyfiveDays?.end.getDate() +
                          "-" +
                          (AdjsixtyfiveDays?.end.getMonth() + 1) +
                          "-" +
                          AdjsixtyfiveDays?.end.getFullYear()}
                      </td>
                      <td className="p-2 border">
                        {AdjsixtyfiveDays?.start.getDate() +
                          "-" +
                          (AdjsixtyfiveDays?.start.getMonth() + 1) +
                          "-" +
                          AdjsixtyfiveDays?.start.getFullYear()}
                      </td>

                      <td className="p-2 border">
                        {AdjsixtyfiveDays?.volume.toLocaleString("en-US")}
                      </td>
                      <td className="p-2 border">
                        ${AdjsixtyfiveDays?.dollar.toLocaleString("en-US")}
                      </td>
                    </tr>
                    <tr className="bg-[#d9ecfc]">
                      <td className="p-2 border">10%</td>
                      <td className="p-2 border">
                       
                      </td>
                      <td className="p-2 border">
                      
                      </td>

                      <td className="p-2 border">
                        {Math.round((AdjsixtyfiveDays?.volume*0.1)).toLocaleString("en-US")}
                      </td>
                      <td className="p-2 border">
                        ${ parseFloat((AdjsixtyfiveDays?.dollar*0.1).toFixed(2)).toLocaleString("en-US")}
                      </td>
                    </tr>

                </tbody>
              </table>

              {/* <PaginationButtons
            totalRecords={totalRecords}
            NumberOfRecordsPerPage={NumberOfRecordsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setGoto={setGoto}
            goto={goto}
            totalPages={totalPages}
          /> */}
            </div>
          </>
        ) : (
          tableCount === 0 && (
            <h1 className="text-center text-gray-500 mt-5">No Data Found</h1>
          )
        )}

        <span className="w-full flex items-center justify-center my-10">
          {tableData?.length >= 1 && (
            <button
              onClick={() => {
                navigate("view-details");
                setShowDetails(true);
              }}
              className="w-full lg:w-1/4 py-2 flex text-white items-center justify-center rounded-md"
              style={{
                backgroundColor: "#640475",
              }}
            >
              View Details
            </button>
          )}

          {/* {showDetails === true ? (
          <ViewDetailsModal
            setShowDetails={setShowDetails}
            Loading={Loading}
            tableData={tableData}
          />
        ) : null} */}
        </span>
      </div>

      <div className={showDetails === false ? "hidden" : "w-full"}>
        <Outlet />
      </div>
    </>
  );
}

export default LiquidityScanner;
