import { createContext, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Routes_Main } from "./MainRoutes";
import Error404 from "./ErrorPages/404/404";
import { Toaster } from "react-hot-toast";
import ManageAdmins from "./Pages/adminDashboard/ManageAdmins";
import AdminLogin from "./components/Admin/Admin-Login/AdminLogin";
import AdminProtectedRoutes from "./components/ProtectedRoutes/AdminProtectedRoutes";
import CompanyRecentShares from "./Pages/adminDashboard/CompanyRecentShares";
import CompanyStatus from "./Pages/adminDashboard/CompanyStatus";
import LiquidityScanner from "./Pages/adminDashboard/LiquidityScanner";
import ViewDetailsModal from "./components/Modals/ViewDetailsModal";

export const ThemeContext = createContext();

function App() {
  const [showDetails, setShowDetails] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [Loading, setLoading] = useState(false);

  return (
    <div className="App">
      <Toaster />

      <ThemeContext.Provider
        value={{
          showDetails,
          setShowDetails,
          tableData,
          setTableData,
          Loading,
          setLoading,
        }}
      >
        <Router>
          <Routes>
            <Route
              path={Routes_Main.adminDashboard}
              element={<AdminProtectedRoutes />}
            >
              <Route path={Routes_Main.adminLogin} element={<AdminLogin />} />
              <Route
                path={Routes_Main.manageAdmins}
                element={<ManageAdmins />}
              />
              <Route
                path={Routes_Main.recentShares}
                element={<CompanyRecentShares />}
              />
              <Route
                path={Routes_Main.companiesStatus}
                element={<CompanyStatus />}
              />

              <Route
                path={Routes_Main.LiquidityScanner}
                element={<LiquidityScanner />}
              >
                <Route
                  path={Routes_Main.view_details}
                  element={<ViewDetailsModal />}
                />
              </Route>

              {/* subadmin routes */}
            </Route>

            <Route path="*" element={<Error404 />} />
          </Routes>
        </Router>
      </ThemeContext.Provider>
    </div>
  );
}

export default App;
