const Routes_Main = {
  adminDashboard: "",
  Dashboard: "/",
  recentShares: "CompanyRecentShares/:code",
  companiesStatus: "companies-statuses",
  manageAdmins: "manage-companies",
  LiquidityScanner: "liquidity-scanner",
  view_details: "view-details",
};
export { Routes_Main };
