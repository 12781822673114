import { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import ScaleLoader from "react-spinners/ScaleLoader";
import AdminRefreshToken from "../../components/Admin/Admin-Refresh-Token/AdminRefreshToken";
import ApiURL from "../../config/config";
import PaginationButtons from "../../components/Pagination/PaginationButtons";
import { Link } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineSearch,
} from "react-icons/ai";
import { Helmet } from "react-helmet";

function CompanyStatus() {
  const [Loading, setLoading] = useState(false);

  const [goto, setGoto] = useState("");
  const [totalPages, setTotalPages] = useState();
  const [totalRecords, setTotalRecords] = useState([]);
  const [NumberOfRecordsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [search, setSearch] = useState("");
  const [TokenRefresh, setTokenRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const [sharesData, setSharesData] = useState([]);
  const handleShowAddModal = () => setShowAddModal(true);
  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const DashboardData = () => {
    setLoading(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const AdminAuth = JSON.parse(localStorage.getItem("AdminAuth"));
    myHeaders.append("Authorization", "Bearer " + AdminAuth.access_token);
    let raw = JSON.stringify({
      query: search,
    });
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,

      redirect: "follow",
    };
    fetch(
      `${ApiURL}/ListCompanies/${NumberOfRecordsPerPage}/${
        (currentPage - 1) * NumberOfRecordsPerPage
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.msg === "Token has expired") {
          AdminRefreshToken({ TokenRefresh, setTokenRefresh });
          setRefresh(!refresh);
        } else if (result.status === 404) {
          setSharesData([]);
        } else {
          console.log(result);
          setSharesData(result.data);
          setTotalPages(result.pages);
        }
      })
      .catch((e) => {
        console.log("error", e);
        toast.error("An error has occurred");
      });
    setLoading(false);
  };

  //delete code

  const deleteCompany = (companyCode) => {
    setLoading(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const AdminAuth = JSON.parse(localStorage.getItem("AdminAuth"));
    myHeaders.append("Authorization", "Bearer " + AdminAuth.access_token);

    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(ApiURL + "/DeleteCompany/" + companyCode, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.msg === "Token has expired") {
          AdminRefreshToken({ TokenRefresh, setTokenRefresh });
        } else if (result.status === 406) {
          toast.error(result.message);
        } else {
          toast.success(result.message);
          DashboardData();

          handleCloseAddModal();
          setRefresh(!refresh);
        }
      })
      .catch((e) => {
        console.log("error", e);
        toast.error("An error has occurred");
      });
    setLoading(false);
  };

  //edit code

  const [editID, seteditID] = useState("");

  const getDataEditModal = (id) => {
    seteditID(id);

    let filterData = sharesData?.filter((obj) => obj.company_code === id);
    console.log(filterData);

    setNameEdit(filterData[0]["name"]);
    setCodeEdit(filterData[0]["company_code"]);
  };

  const [codeEdit, setCodeEdit] = useState("");
  const [nameEdit, setNameEdit] = useState("");

  const editCompany = () => {
    setLoading(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const AdminAuth = JSON.parse(localStorage.getItem("AdminAuth"));
    myHeaders.append("Authorization", "Bearer " + AdminAuth.access_token);

    var raw = JSON.stringify({
      code: codeEdit,
      name: nameEdit,
    });

    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    console.log(raw);
    fetch(ApiURL + "/EditCompany/" + editID, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.msg === "Token has expired") {
          AdminRefreshToken({ TokenRefresh, setTokenRefresh });
        } else if (result.status === 406) {
          toast.error(result.message);
        } else {
          console.log(result);
          toast.success(result.message);
          DashboardData();

          handleCloseAddModal();
          setRefresh(!refresh);
        }
      })
      .catch((e) => {
        console.log("error", e);
        toast.error("An error has occurred");
      });
    setLoading(false);
  };

  useEffect(() => {
    DashboardData();
  }, [refresh, currentPage]);
  if (Loading) {
    return <ScaleLoader />;
  } else {
    return (
      <>
        <Helmet>
          <title>OTC Market | Manage Statuses</title>
        </Helmet>

        <div className="w-full mb-4 flex justify-center items-center flex-col-reverse lg:flex-row">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              currentPage !== 1 ? setCurrentPage(1) : setRefresh(!refresh);
            }}
            className="form w-full lg:w-1/3 flex items-center"
          >
            <div
              className="relative w-full flex justify-center self-center"
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              <input
                type="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="w-full border-y border-l border-gray-700 py-2 rounded-md rounded-r-none px-3 "
                placeholder="Search Query"
              />
              {show && (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSearch("");
                    setRefresh(!refresh);
                  }}
                  className="absolute top-2 right-0 my-1 mx-2"
                >
                  <MdCancel />
                </span>
              )}
            </div>
            <button className="w-14 h-11 rounded-md rounded-l-none bg-black text-white text-xl flex items-center justify-center">
              <AiOutlineSearch />
            </button>
          </form>
        </div>

        {/* <div className="search-container">
            <form
              className="form"
              onSubmit={(e) => {
                e.preventDefault();
                currentPage !== 1 ? setCurrentPage(1) : setRefresh(!refresh);
              }}
            >
              <div
                className="position-relative"
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
              >
                <input
                  type="text"
                  className="form-control w-100 px-3"
                  placeholder="Search Query"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {show && (
                  <span
                    style={{ right: "0px", top: "0px", cursor: "pointer" }}
                    onClick={() => {
                      setSearch("");
                      setRefresh(!refresh);
                    }}
                    className="position-absolute my-1 mx-2"
                  >
                    {" "}
                    <MdCancel />
                  </span>
                )}
              </div>
            </form>
          </div> */}

        {/* TABLE THAT IS DISPLAYING ALL THE DATA  */}
        <div className="container table_main">
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Company's Name</th>
                <th scope="col" className="text-center">
                  Company's Code
                </th>
                <th scope="col" className="text-center">
                  Status
                </th>
                <th scope="col" className="text-center">
                  Actions
                </th>
              </tr>
            </thead>
            {Loading === true ? (
              <div className="d-flex position-absolute w-75 justify-content-center">
                <ScaleLoader color={"#25476A"} loading={Loading} size={20} />
              </div>
            ) : (
              <tbody>
                {sharesData.map((val) => (
                  <tr>
                    <td>{val.name}</td>
                    <td className="text-center">{val.company_code}</td>
                    <td className="text-center">{val.text}</td>

                    <td className="text-center">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          getDataEditModal(val.company_code);
                          handleShowAddModal();
                        }}
                      >
                        {" "}
                        <AiOutlineEdit />
                      </button>

                      {/* Edit Companies MODAL  */}
                      <Modal show={showAddModal} onHide={handleCloseAddModal}>
                        <Modal.Header closeButton>
                          <Modal.Title>Edit Company</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="w-100 text-center">
                            <p className="text-danger" id="error"></p>
                          </div>
                          <div className="p-3 pt-0">
                            <input
                              style={{
                                border: "1px solid gray",
                                padding: "5px 10px",
                              }}
                              className="w-100 mb-3"
                              type="text"
                              placeholder="Company's Name"
                              required
                              value={nameEdit}
                              onChange={(e) => setNameEdit(e.target.value)}
                            />
                            <input
                              style={{
                                border: "1px solid gray",
                                padding: "5px 10px",
                              }}
                              className="w-100 mb-3"
                              type="text"
                              placeholder="Company's Ticker"
                              required
                              value={codeEdit}
                              onChange={(e) => setCodeEdit(e.target.value)}
                            />
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <div>
                            <button
                              style={{
                                color: "white",
                                border: "none",
                                backgroundColor: "#f54242",
                                borderRadius: "5px",
                                marginLeft: "8pt",
                                padding: "5px 10px",
                              }}
                              onClick={handleCloseAddModal}
                            >
                              Close
                            </button>
                            <button
                              disabled={Loading}
                              style={{
                                color: "white",
                                border: "none",
                                backgroundColor: "black",
                                borderRadius: "5px",
                                marginLeft: "8pt",
                                padding: "5px 10px",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                editCompany(val.companyCode);
                              }}
                            >
                              {Loading === false ? (
                                "Edit Company"
                              ) : (
                                <ScaleLoader
                                  color={"white"}
                                  size={2}
                                  style={{ zIndex: "-10", padding: "5px 10px" }}
                                />
                              )}
                            </button>
                          </div>
                        </Modal.Footer>
                      </Modal>

                      <button
                        className="btn btn-danger ms-1"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteCompany(val.company_code);
                        }}
                      >
                        {" "}
                        <AiOutlineDelete />
                      </button>
                    </td>
                    <td className="text-center"></td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        <PaginationButtons
          totalRecords={totalRecords}
          NumberOfRecordsPerPage={NumberOfRecordsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setGoto={setGoto}
          goto={goto}
          totalPages={totalPages}
        />
      </>
    );
  }
}

export default CompanyStatus;
