import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import { Container } from "reactstrap";
import TopBar from "./Topbar/Topbar";

const Dashboard = (props) => {
  return (
    <div className="w-full flex">
      {/********Sidebar**********/}

      <Sidebar
        RefreshAdminLogin={props.RefreshAdminLogin}
        setRefreshAdminLogin={props.setRefreshAdminLogin}
      />

      {/********Content Area**********/}
      <div className="w-full flex flex-row-reverse">
        <div className="w-full lg:w-[80%] mt-5" style={{ position: "" }}>
          {/********header**********/}
          <div
            className="top-wrapper"
            style={{
              position: "fixed",
              top: "0%",
              zIndex: "99",
              width: "100%",
            }}
          >
            <TopBar
              RefreshAdminLogin={props.RefreshAdminLogin}
              setRefreshAdminLogin={props.setRefreshAdminLogin}
            />
          </div>

          {/********Middle Content**********/}
          <div className="p-4 w-full mt-4 ">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
