import React, { useContext } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { BsArrowLeftCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { ThemeContext } from "../../App";

function ViewDetailsModal() {
  let navigate = useNavigate();

  const { setShowDetails, tableData, Loading } = useContext(ThemeContext);
  return (
    <div className="w-full flex justify-center">
      <div className="border-0 rounded-lg relative flex flex-col w-11/12 bg-white outline-none focus:outline-none">
        <div className="w-[70%] z-30 bg-white border rounded-md shadow-lg fixed top-20 flex items-center justify-between px-5 py-3 border-b border-solid border-slate-200 rounded-t">
          <button
            className="self-center flex justify-between items-center hover:text-gray-400 text-sm font-semibold mr-1 whitespace-nowrap"
            type="button"
            onClick={() => {
              navigate("/liquidity-scanner");
              setShowDetails(false);
            }}
          >
            <AiOutlineLeft className="mr-1 text-sm" />
            Go Back
          </button>

          <span className="w-full flex justify-end">
            <button className="bg-[#640475] px-5 py-2 text-sm rounded-md text-white">
              Export CSV
            </button>
          </span>
        </div>

        {/*body*/}
        <div className="w-full px-5 mt-16">
          <h2 className="text-2xl font-bold pt-4">Details</h2>
          {/* TABLE THAT IS DISPLAYING ALL THE DATA  */}
          <div
            className="container table_main mt-4"
            style={{ "overflow-x": "scroll" }}
          >
            <table class="table">
              <thead class="thead-dark">
                <tr>
                  <th scope="col" className="">
                    Date
                  </th>
                  <th scope="col" className="">
                    Open
                  </th>
                  <th scope="col" className="">
                    High
                  </th>
                  <th scope="col" className="">
                    Low
                  </th>
                  <th scope="col" className="">
                    Close
                  </th>
                  <th scope="col" className="">
                    Volume
                  </th>
                  <th scope="col" className="">
                    EOD VWAP
                  </th>
                  {/* <th scope="col" className="">
                        Total Liquidity
                      </th> */}
                </tr>
              </thead>
              {Loading === true ? (
                <div className="d-flex position-absolute w-75 justify-content-center">
                  <ScaleLoader color={"#25476A"} loading={Loading} size={20} />
                </div>
              ) : (
                <tbody>
                  {tableData?.map((val) => (
                    <tr>
                      <td className="p-2 border">    {val.t.getDate() +
                          "-" +
                          (val.t.getMonth() + 1) +
                          "-" +
                          val.t.getFullYear()}</td>
                      <td className="p-2 border">{val.o}</td>
                      <td className="p-2 border">{val.h}</td>
                      <td className="p-2 border">{val.l}</td>
                      <td className="p-2 border">{val.c}</td>
                      <td className="p-2 border">{val.v.toLocaleString("en-US")}</td>
                      <td className="p-2 border">{val.vw}</td>
                      {/* <td className="p-2 border">$5515.01</td> */}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>

            {/* <PaginationButtons
                totalRecords={totalRecords}
                NumberOfRecordsPerPage={NumberOfRecordsPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setGoto={setGoto}
                goto={goto}
                totalPages={totalPages}
              /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewDetailsModal;
