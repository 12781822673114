import { Button, Nav, NavItem } from "reactstrap";
import Logo from "../../../assets/logo.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";
import { useState } from "react";

const navigation = [
  {
    title: "Manage Companies",
    href: "manage-companies",
    icon: "M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z",
  },
  {
    title: "Companies Statuses",
    href: "companies-statuses",
    icon: "M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z",
  },

  {
    title: "Liquidity Scanner",
    href: "liquidity-scanner",
    icon: "M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z",
  },
];

const Sidebar = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleLogout = () => {
    localStorage.setItem("AdminIsLogin", "");
    localStorage.setItem("AdminAuth", "");
    props.setRefreshAdminLogin(props.RefreshAdminLogin === true ? false : true);
  };

  return (
    <>
      {/* main-menu */}
      <div className="w-0 lg:w-[20%] fixed">
        <div
          className="hidden w-full lg:block"
          style={{
            boxShadow: "5px 5px 15px lightgray",
          }}
        >
          <div
            style={{ padding: "16px 0px", background: "#237569" }}
            className="flex items-center"
          >
            <h4
              className="text-white text-center mx-5 "
              style={{ fontSize: "18px", fontWeight: "500", margin: "0" }}
            >
              OTC Markets
            </h4>
          </div>
          <div className="" style={{ height: "100vh" }}>
            <div className="py-4 my-2 flex flex-col items-center px-3">
              <span className="w-full">
                {navigation.map((navi, index) => (
                  <NavLink
                    end
                    style={({ isActive }) => ({
                      color: isActive ? "white" : "black",
                      background: isActive ? "black" : "",
                    })}
                    className="rounded-md py-2 my-8 px-3 flex items-center"
                    to={navi.href}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      style={{ width: "20px", height: "20px" }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d={navi.icon}
                      />
                    </svg>
                    <span
                      style={{ textDecoration: "none" }}
                      className="ms-3 self-center"
                    >
                      {navi.title}
                    </span>
                  </NavLink>
                ))}
              </span>

              <button
                onClick={handleLogout}
                className="w-full py-2 flex text-white items-center justify-center rounded-md"
                style={{
                  backgroundColor: "#640475",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "15px",
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
                  />
                </svg>
                Log out
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* mobile-menu */}
      <div className="sidebar-wrapper mt-lg-2  d-lg-none">
        <button className="btn" onClick={handleShow}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            style={{
              width: "20px",
              height: "20px",
              color: "white",
              outline: "none",
              position: "fixed",
            }}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
      </div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>OTC Markets</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div class="offcanvas-body">
            <div className="py-4 my-2 flex flex-col items-center">
              <span className="w-full">
                {navigation.map((navi, index) => (
                  <NavLink
                    end
                    style={({ isActive }) => ({
                      color: isActive ? "white" : "black",
                      background: isActive ? "black" : "",
                    })}
                    className="rounded-md py-2 my-8 px-3 flex items-center"
                    to={navi.href}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      style={{ width: "20px", height: "20px" }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d={navi.icon}
                      />
                    </svg>
                    <span
                      style={{ textDecoration: "none" }}
                      className="ms-3 self-center"
                    >
                      {navi.title}
                    </span>
                  </NavLink>
                ))}
              </span>

              <button
                onClick={handleLogout}
                className="w-full py-2 flex text-white items-center justify-center rounded-md"
                style={{
                  backgroundColor: "#640475",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "15px",
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
                  />
                </svg>
                Log out
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Sidebar;
