import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./Topbar.css";
import {
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavbarBrand,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from "reactstrap";
// import { ReactComponent as LogoWhite } from "../assets/images/logos/xtremelogowhite.svg";
import user1 from "../../../assets/user.png";
import AdminRefreshToken from "../../Admin/Admin-Refresh-Token/AdminRefreshToken";
import { useEffect } from "react";
import ApiURL from "../../../config/config";

const TopBar = (props) => {

  let navigate = useNavigate();
  const [username,setUserName]=useState("")
  const [TokenRefresh, setTokenRefresh] = useState(true);
  const [refresh, setRefresh] = useState(false);
const fetchingData=()=>{
    const items = JSON.parse(localStorage.getItem("AdminAuth"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + items.access_token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(ApiURL + "/admin/details", requestOptions)
    .then(response =>response.json())
    .then((result) => {
      if (result.msg === "Token has expired") {
        AdminRefreshToken({ TokenRefresh, setTokenRefresh });
        setRefresh(!refresh)
      
      }
      else{
    
      setUserName(result.details.username)
      }
    })

  }
  // useEffect(()=>{
  //   fetchingData()
  // },[])
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleLogout = () => {
    localStorage.setItem("AdminIsLogin", "");
    localStorage.setItem("AdminAuth", "");
    props.setRefreshAdminLogin(props.RefreshAdminLogin === true ? false : true);
  };
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const Handletoggle = () => {
    setIsOpen(!isOpen);
  };
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };
  return (
    <Navbar className="" style={{ background: "#237569" }} dark expand="md">
      <div
        
        className="d-flex w-100 justify-content-between align-items-center"
      >
        <Nav className="m-auto center" >
          <NavItem>
            <h4
              to="/starter"
              className="nav-link text-white"
              style={{ fontSize: "18px", fontWeight: "500", margin: "0" }}
            >
            Dashboard
            </h4>
          </NavItem>
        </Nav>
       
        
      </div>
    </Navbar>
  );
};

export default TopBar;
